.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.vl {
  display: flex;
  align-self: center;
  border: 1px solid rgb(157, 157, 157);
  height: 550px;
  width: 0.1px;
}

.payment-form {
  background-color: #023059;
}

.pay-btn {
  background-color: #f26363;
  border-color: #f26363;
  &:hover {
    border-color: #f26363;
    background-color: #f26363;
  }
}

.n-genius-btn {
  background-color: #155b99;
  color: white;
  border-color: #155b99;

  &:hover {
    background-color: #155b99;
    color: white;
    border-color: #155b99;
  }
}
p{
  font-size: small;
}