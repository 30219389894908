.text-input {
    margin: 10px 0 10px 0;
}

.text-input-data:focus {
    outline: none;
}

.text-input-data:hover {
    border-color: rgba(14, 14, 14, .568627451);
}