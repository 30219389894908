.catalogue-container {
    display: grid;
    grid-gap: 24px 24px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    max-width: 100%;
    overflow: hidden;
}

.catalogue-total-amount {
    align-self: flex-end;
    margin-right: 30px;
    font-weight: bolder;
    font-size: 18px;
}
