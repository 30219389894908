@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300&display=swap');

.merchant-configurations {
    font-family: 'Catamaran', sans-serif;
    font-size: 13px;
}

.radio-heading {
    font-weight: bolder;
    margin: 0 auto;
}

.merchant-configurations-header {
    font-weight: bolder;
}

.accordion {
    --bs-accordion-active-bg: white;
    --bs-accordion-btn-focus-border-color: white;
    --bs-accordion-active-color: black;
}

.accordion:focus {
    outline: 0 !important;
}
.create-order-button {
    background-color: black;
    border: none;
}

.scroll-form{
    max-height: 35rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-form::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .scroll-form {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }