.item {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.item-image-container {
    background-color: #F8F9FA;
    text-align: center;
}

.item-image {
    width: 250px;
    height: auto;
    padding: 40px 4%;
    transition: transform .2s
}

.item-image:hover {
    transform: scale(1.2);
}

.item-name {
    font-weight: bolder;
    font-size: 16px;
    line-height: 20px;
}

.item-amount {
    font-size: 16px;
    align-items: flex-end;
    margin-top: 5px;
    color: #636060;
    font-weight: lighter;
}

.item-details-container {
    display: flex;
    flex-direction: row;
    margin: 10px 10px 10px 10px;
    padding: 5px;
}

.item-quantity {
    display: flex;
    flex-direction: row;
    height: min-content;
}

.item-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    padding-top: 15px;
}

.quantity {
    font-weight: bold;
    padding: 10px;
}

.item-price {
    font-weight: bolder;
    text-align: center;
    padding: 10px;
}

.item-quantity-price {
    align-self: center;
    display: flex;
    flex-direction: column;
}
