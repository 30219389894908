.env-section {
    font-size: 13px;
}

.custom-access-mode-option {
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
}

.custom-section {
    margin-left: 15px;
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 10px;
}

.custom-access-mode {
    display: grid;
}

.section-enabled-false {
    opacity: 60%;
}
