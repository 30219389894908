.dropdown{
    margin: 10px 0 10px 0;
}
.dropdown-content{
    height: 48px;
    transition: black 200ms ease-out,grey 200ms ease-out;
    box-shadow: 0 1px 6px 0 rgba(14,14,14,.0901960784);
    font-size: 16px;
    width: 100%;
    border-radius: 6px;
    background: #fff;
    border: 1px solid rgba(14,14,14,.1803921569);
    color: #222;
    cursor: pointer;

}

.dropdown-content::-ms-expand {
    display: none
}

.dropdown-content:focus{
    outline: none;
    border-color: rgba(14,14,14,.568627451);
}

.dropdown-content:hover {
    border-color: rgba(14,14,14,.568627451);
    box-shadow: 0 2px 12px 0 rgba(14,14,14,.1803921569)
}

