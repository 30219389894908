.radio-options {
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
}

input[type="radio"] {
    appearance: none;
    margin: 0;
    width: 14px;
    height: 14px;
    border: 1px solid black;
    border-radius: 100px;
    display: grid;
    place-content: center;
    cursor: pointer;
    align-self: center;
}

.form-radio-button + .form-radio-button {
    margin-top: 10px;
}

input[type="radio"]::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid black;
    transform: scale(0);
    transition: all 100ms ease-out;
}

input[type="radio"]:checked::before {
    transform: scale(1.5);
}
