.create-order {
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.create-order-merchant-config {
  flex-grow: 1;
  width: 10%;
}

.create-order-catalogue {
  display: grid;
  flex-grow: 4;
  grid-template-rows: 40px 1fr;
  row-gap: 2px;
}

.create-order-button {
  background-color: black;
  border: none;
}

.create-order-proceed {
  padding: 5px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: right;
  align-items: center;
}

.create-order-total {
  font-weight: bolder;
}

.slide {
  animation: slider 0.4s ease-in;
}

@keyframes slider {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.merchant-parent::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .merchant-parent {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
