/* Login.css */
.login-container {
    display: flex;
    justify-content: center;
}

.login-img {
    width: 500px;
    height: auto;
    margin-right: 20px;
}

.login-divider {
    width: 1px;
    height: 80vh;
    background-color: #ccc;
    margin: 0 20px;
}

.login-form {
    width: 400px;
    height: 400px;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.login-form h2 {
    margin-bottom: 20px;
    align-self: center;
}

.login-form .form-group {
    margin-bottom: 20px;
}

.login-form label {
    display: block;
    margin-bottom: 5px;
}

.login-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.login-form .error {
    margin-top: 10px;
}

.login-btn {
    width: 80%;
    margin-left: 10%;
    margin-top: 10%;
    padding: 10px;
    background-color: #002e5d;
    box-sizing: border-box;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: center;
    justify-content: center;
}


body {
    margin: 0;
}

@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
    }

    .login-img {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .login-divider {
        display: none;
    }

    .login-form {
        width: 100%;
        height: auto;
    }

    .login-form input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
    }

    .login-form button {
        width: 100%;
    }
}
