@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

.font-face-gm {
    font-family: "Montserrat", sans-serif;
}

body {
    font-family: "Montserrat", sans-serif;
    margin: 0;
}
