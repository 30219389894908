/* General scrollbar styling */
::-webkit-scrollbar {
  width: 10px; /* Overall width */
}

/* Track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

/* Thumb (the draggable scrolling handle) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 5px; /* Rounded edges */
}

/* Hover effect for thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}